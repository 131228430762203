/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MyGroups
 */
export interface MyGroups {
    /**
     * 
     * @type {string}
     * @memberof MyGroups
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MyGroups
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MyGroups
     */
    avatar: string;
    /**
     * 
     * @type {boolean}
     * @memberof MyGroups
     */
    emailNotification: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyGroups
     */
    pushNotification: boolean;
}

/**
 * Check if a given object implements the MyGroups interface.
 */
export function instanceOfMyGroups(value: object): value is MyGroups {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('avatar' in value) || value['avatar'] === undefined) return false;
    if (!('emailNotification' in value) || value['emailNotification'] === undefined) return false;
    if (!('pushNotification' in value) || value['pushNotification'] === undefined) return false;
    return true;
}

export function MyGroupsFromJSON(json: any): MyGroups {
    return MyGroupsFromJSONTyped(json, false);
}

export function MyGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MyGroups {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['_id'],
        'name': json['name'],
        'avatar': json['avatar'],
        'emailNotification': json['emailNotification'],
        'pushNotification': json['pushNotification'],
    };
}

export function MyGroupsToJSON(json: any): MyGroups {
    return MyGroupsToJSONTyped(json, false);
}

export function MyGroupsToJSONTyped(value?: MyGroups | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        '_id': value['id'],
        'name': value['name'],
        'avatar': value['avatar'],
        'emailNotification': value['emailNotification'],
        'pushNotification': value['pushNotification'],
    };
}

