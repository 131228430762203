/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatedGroup,
  Group,
  GroupBody,
} from '../models/index';
import {
    CreatedGroupFromJSON,
    CreatedGroupToJSON,
    GroupFromJSON,
    GroupToJSON,
    GroupBodyFromJSON,
    GroupBodyToJSON,
} from '../models/index';

export interface CreateGroupRequest {
    accessToken: string;
    groupBody?: GroupBody;
}

export interface DeleteGroupRequest {
    accessToken: string;
    id: string;
}

export interface GetGroupRequest {
    accessToken: string;
    id: string;
}

export interface UpdateGroupRequest {
    accessToken: string;
    id: string;
    groupBody?: GroupBody;
}

/**
 * 
 */
export class GroupsApi extends runtime.BaseAPI {

    /**
     * Create a new group
     */
    async createGroupRaw(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatedGroup>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling createGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupBodyToJSON(requestParameters['groupBody']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatedGroupFromJSON(jsonValue));
    }

    /**
     * Create a new group
     */
    async createGroup(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatedGroup> {
        const response = await this.createGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete group
     */
    async deleteGroupRaw(requestParameters: DeleteGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling deleteGroup().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete group
     */
    async deleteGroup(requestParameters: DeleteGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Get a group by ID
     */
    async getGroupRaw(requestParameters: GetGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Group>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling getGroup().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupFromJSON(jsonValue));
    }

    /**
     * Get a group by ID
     */
    async getGroup(requestParameters: GetGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Group> {
        const response = await this.getGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update group
     */
    async updateGroupRaw(requestParameters: UpdateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling updateGroup().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: GroupBodyToJSON(requestParameters['groupBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update group
     */
    async updateGroup(requestParameters: UpdateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateGroupRaw(requestParameters, initOverrides);
    }

}
