/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotificationBody,
} from '../models/index';
import {
    NotificationBodyFromJSON,
    NotificationBodyToJSON,
} from '../models/index';

export interface UpdateNotifcationRequest {
    accessToken: string;
    id: string;
    notificationBody?: NotificationBody;
}

/**
 * 
 */
export class GroupNotificationsApi extends runtime.BaseAPI {

    /**
     * Turn of / off notifications
     */
    async updateNotifcationRaw(requestParameters: UpdateNotifcationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling updateNotifcation().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateNotifcation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/notifications`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationBodyToJSON(requestParameters['notificationBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Turn of / off notifications
     */
    async updateNotifcation(requestParameters: UpdateNotifcationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNotifcationRaw(requestParameters, initOverrides);
    }

}
