/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    id: string;
    /**
     * 
     * @type {UserProfile}
     * @memberof Order
     */
    user: UserProfile;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    drink?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    text?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    extras?: string;
}

/**
 * Check if a given object implements the Order interface.
 */
export function instanceOfOrder(value: object): value is Order {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    return true;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['_id'],
        'user': UserProfileFromJSON(json['user']),
        'drink': json['drink'] == null ? undefined : json['drink'],
        'text': json['text'] == null ? undefined : json['text'],
        'extras': json['extras'] == null ? undefined : json['extras'],
    };
}

export function OrderToJSON(json: any): Order {
    return OrderToJSONTyped(json, false);
}

export function OrderToJSONTyped(value?: Order | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        '_id': value['id'],
        'user': UserProfileToJSON(value['user']),
        'drink': value['drink'],
        'text': value['text'],
        'extras': value['extras'],
    };
}

