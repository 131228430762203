/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Drawing } from './Drawing';
import {
    DrawingFromJSON,
    DrawingFromJSONTyped,
    DrawingToJSON,
    DrawingToJSONTyped,
} from './Drawing';
import type { GroupMember } from './GroupMember';
import {
    GroupMemberFromJSON,
    GroupMemberFromJSONTyped,
    GroupMemberToJSON,
    GroupMemberToJSONTyped,
} from './GroupMember';
import type { InviteAsMember } from './InviteAsMember';
import {
    InviteAsMemberFromJSON,
    InviteAsMemberFromJSONTyped,
    InviteAsMemberToJSON,
    InviteAsMemberToJSONTyped,
} from './InviteAsMember';
import type { Drink } from './Drink';
import {
    DrinkFromJSON,
    DrinkFromJSONTyped,
    DrinkToJSON,
    DrinkToJSONTyped,
} from './Drink';

/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    avatar: string;
    /**
     * 
     * @type {boolean}
     * @memberof Group
     */
    active: boolean;
    /**
     * 
     * @type {Array<InviteAsMember>}
     * @memberof Group
     */
    invites: Array<InviteAsMember>;
    /**
     * 
     * @type {Array<GroupMember>}
     * @memberof Group
     */
    members: Array<GroupMember>;
    /**
     * 
     * @type {Array<Drink>}
     * @memberof Group
     */
    drinks?: Array<Drink>;
    /**
     * 
     * @type {Drawing}
     * @memberof Group
     */
    activeDrawing?: Drawing;
    /**
     * 
     * @type {Drawing}
     * @memberof Group
     */
    lastDrawing?: Drawing;
}

/**
 * Check if a given object implements the Group interface.
 */
export function instanceOfGroup(value: object): value is Group {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('avatar' in value) || value['avatar'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('invites' in value) || value['invites'] === undefined) return false;
    if (!('members' in value) || value['members'] === undefined) return false;
    return true;
}

export function GroupFromJSON(json: any): Group {
    return GroupFromJSONTyped(json, false);
}

export function GroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Group {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['_id'],
        'name': json['name'],
        'avatar': json['avatar'],
        'active': json['active'],
        'invites': ((json['invites'] as Array<any>).map(InviteAsMemberFromJSON)),
        'members': ((json['members'] as Array<any>).map(GroupMemberFromJSON)),
        'drinks': json['drinks'] == null ? undefined : ((json['drinks'] as Array<any>).map(DrinkFromJSON)),
        'activeDrawing': json['activeDrawing'] == null ? undefined : DrawingFromJSON(json['activeDrawing']),
        'lastDrawing': json['lastDrawing'] == null ? undefined : DrawingFromJSON(json['lastDrawing']),
    };
}

export function GroupToJSON(json: any): Group {
    return GroupToJSONTyped(json, false);
}

export function GroupToJSONTyped(value?: Group | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        '_id': value['id'],
        'name': value['name'],
        'avatar': value['avatar'],
        'active': value['active'],
        'invites': ((value['invites'] as Array<any>).map(InviteAsMemberToJSON)),
        'members': ((value['members'] as Array<any>).map(GroupMemberToJSON)),
        'drinks': value['drinks'] == null ? undefined : ((value['drinks'] as Array<any>).map(DrinkToJSON)),
        'activeDrawing': DrawingToJSON(value['activeDrawing']),
        'lastDrawing': DrawingToJSON(value['lastDrawing']),
    };
}

