import GroupMenu from "./GroupMenu";
import ProfileMenu from "./ProfileMenu";
import OnlineUsers from "./OnlineUsers";
import { Divider } from "@nextui-org/react";
interface Props {
  onCreateNewGroup: () => void;
  onEditProfile: () => void;
}
const Sidebar: React.FC<Props> = (props) => {
  return (
    <div className="flex-none w-24 xl:w-80 bg-primary-100 flex flex-col p-4 transition-width" id="bar">
      <GroupMenu onCreateNewGroup={props.onCreateNewGroup} />
      <Divider className="my-4" />
      <div className="grow overflow-y-scroll hidescroll">
        <OnlineUsers />
      </div>
      <div>
        <Divider className="my-4" />
        <ProfileMenu openSettings={props.onEditProfile} />
      </div>
    </div>
  );
};

export default Sidebar;
