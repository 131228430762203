import { useEffect, useState } from "react";
import Dashboard from "./Dashboard/Dashboard";
import Onboard from "./Onboard/Onboard";
import Alert from "./Alert";
import { useGetProfileFunction, useProfile } from "../../Context/Profile";
import Loading from "../../Components/layout/Loading";

const Main: React.FC = () => {
  const profile = useProfile();
  const getProfileFunction = useGetProfileFunction();

  const [isNewUser, setIsNewUser] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    // Load profile
    getProfileFunction && getProfileFunction();
  }, []);

  useEffect(() => {
    if (!profile) return;
    if ((!profile.name || !profile.avatar) && profile.email) {
      setIsNewUser(true);
    } else {
      setIsNewUser(false);
    }
  }, [profile]);

  return (
    <>
      <Alert />
      {isNewUser == undefined && <Loading />}
      {isNewUser == true && <Onboard />}
      {isNewUser == false && <Dashboard />}
    </>
  );
};

export default Main;
