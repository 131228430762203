/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Drawing,
  PlaceOrderBody,
  StartDrawingBody,
} from '../models/index';
import {
    DrawingFromJSON,
    DrawingToJSON,
    PlaceOrderBodyFromJSON,
    PlaceOrderBodyToJSON,
    StartDrawingBodyFromJSON,
    StartDrawingBodyToJSON,
} from '../models/index';

export interface GetDrawingRequest {
    accessToken: string;
    id: string;
    drawingId: string;
}

export interface PlaceOrderRequest {
    accessToken: string;
    id: string;
    drawingId: string;
    placeOrderBody?: PlaceOrderBody;
}

export interface StartDrawingRequest {
    accessToken: string;
    id: string;
    startDrawingBody?: StartDrawingBody;
}

/**
 * 
 */
export class GroupDrawingsApi extends runtime.BaseAPI {

    /**
     * Get drawing
     */
    async getDrawingRaw(requestParameters: GetDrawingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Drawing>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling getDrawing().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getDrawing().'
            );
        }

        if (requestParameters['drawingId'] == null) {
            throw new runtime.RequiredError(
                'drawingId',
                'Required parameter "drawingId" was null or undefined when calling getDrawing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/drawings/{drawingId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"drawingId"}}`, encodeURIComponent(String(requestParameters['drawingId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DrawingFromJSON(jsonValue));
    }

    /**
     * Get drawing
     */
    async getDrawing(requestParameters: GetDrawingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Drawing> {
        const response = await this.getDrawingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Place order
     */
    async placeOrderRaw(requestParameters: PlaceOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling placeOrder().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling placeOrder().'
            );
        }

        if (requestParameters['drawingId'] == null) {
            throw new runtime.RequiredError(
                'drawingId',
                'Required parameter "drawingId" was null or undefined when calling placeOrder().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/drawings/{drawingId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"drawingId"}}`, encodeURIComponent(String(requestParameters['drawingId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlaceOrderBodyToJSON(requestParameters['placeOrderBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Place order
     */
    async placeOrder(requestParameters: PlaceOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.placeOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Start drawing
     */
    async startDrawingRaw(requestParameters: StartDrawingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling startDrawing().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling startDrawing().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/drawings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartDrawingBodyToJSON(requestParameters['startDrawingBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start drawing
     */
    async startDrawing(requestParameters: StartDrawingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.startDrawingRaw(requestParameters, initOverrides);
    }

}
