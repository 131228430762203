/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartDrawingBody
 */
export interface StartDrawingBody {
    /**
     * 
     * @type {number}
     * @memberof StartDrawingBody
     */
    seconds: number;
    /**
     * 
     * @type {boolean}
     * @memberof StartDrawingBody
     */
    self: boolean;
}

/**
 * Check if a given object implements the StartDrawingBody interface.
 */
export function instanceOfStartDrawingBody(value: object): value is StartDrawingBody {
    if (!('seconds' in value) || value['seconds'] === undefined) return false;
    if (!('self' in value) || value['self'] === undefined) return false;
    return true;
}

export function StartDrawingBodyFromJSON(json: any): StartDrawingBody {
    return StartDrawingBodyFromJSONTyped(json, false);
}

export function StartDrawingBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartDrawingBody {
    if (json == null) {
        return json;
    }
    return {
        
        'seconds': json['seconds'],
        'self': json['self'],
    };
}

export function StartDrawingBodyToJSON(json: any): StartDrawingBody {
    return StartDrawingBodyToJSONTyped(json, false);
}

export function StartDrawingBodyToJSONTyped(value?: StartDrawingBody | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'seconds': value['seconds'],
        'self': value['self'],
    };
}

