/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface GroupMember
 */
export interface GroupMember {
    /**
     * 
     * @type {string}
     * @memberof GroupMember
     */
    id: string;
    /**
     * 
     * @type {UserProfile}
     * @memberof GroupMember
     */
    user: UserProfile;
    /**
     * 
     * @type {boolean}
     * @memberof GroupMember
     */
    owner: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupMember
     */
    emailNotification: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GroupMember
     */
    pushNotification: boolean;
}

/**
 * Check if a given object implements the GroupMember interface.
 */
export function instanceOfGroupMember(value: object): value is GroupMember {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('owner' in value) || value['owner'] === undefined) return false;
    if (!('emailNotification' in value) || value['emailNotification'] === undefined) return false;
    if (!('pushNotification' in value) || value['pushNotification'] === undefined) return false;
    return true;
}

export function GroupMemberFromJSON(json: any): GroupMember {
    return GroupMemberFromJSONTyped(json, false);
}

export function GroupMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupMember {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['_id'],
        'user': UserProfileFromJSON(json['user']),
        'owner': json['owner'],
        'emailNotification': json['emailNotification'],
        'pushNotification': json['pushNotification'],
    };
}

export function GroupMemberToJSON(json: any): GroupMember {
    return GroupMemberToJSONTyped(json, false);
}

export function GroupMemberToJSONTyped(value?: GroupMember | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        '_id': value['id'],
        'user': UserProfileToJSON(value['user']),
        'owner': value['owner'],
        'emailNotification': value['emailNotification'],
        'pushNotification': value['pushNotification'],
    };
}

