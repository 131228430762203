import React from "react";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, User } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useSignOut } from "../../../../Context/Auth";
import { useProfile } from "../../../../Context/Profile";
const avatarUrl = import.meta.env.VITE_AVATAR_URL;

type Props = { openSettings: () => void };

const ProfileMenu: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const profile = useProfile();

  const handleSignOut = () => {
    signOut && signOut();
    navigate("/login");
  };
  return (
    <Dropdown>
      <DropdownTrigger>
        <div className="bg-primary-200 p-2 flex cursor-pointer rounded-lg">
          <User
            name={profile?.name}
            classNames={{ name: "hidden xl:block" }}
            avatarProps={{
              src: `${avatarUrl}${profile?.avatar}`
            }}
          />
        </div>
      </DropdownTrigger>
      <DropdownMenu aria-label="Actions">
        <DropdownItem key="edit" onPress={props.openSettings}>
          Instellingen
        </DropdownItem>
        <DropdownItem key="logout" className="text-danger" color="danger" onPress={handleSignOut}>
          Uitloggen
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileMenu;
