import { BiSolidCog, BiLogoApple, BiLogoAndroid } from "react-icons/bi";

import StartDrawing from "./Modals/StartDrawing";
import Result from "./result";
import NewGroupInfo from "./NewGroupInfo";
import NoActiveDrawing from "./NoActiveDrawing";

import PlaceOrder from "./Modals/PlaceOrder";
import ActiveDrawing from "./ActiveDrawing";

import NoGroup from "./NoGroup";
import OrderDetails from "./Modals/OrderDetails";
import { useMyGroups, useProfile } from "../../../../Context/Profile";
import {
  useCurrentGroup,
  useLoadGroupById,
  useRefreshCurrentGroup,
} from "../../../../Context/CurrentGroup";
import { placeOrderFunction } from "../../../../utils/group";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  onCreateNewGroup: () => void;
  onEditGroup: () => void;
  onGetApp: () => void;
};
export type SettingsContext = null | "profile" | "group";

const ActiveGroup: React.FC<Props> = (props) => {
  const myGroups = useMyGroups();
  const currentGroup = useCurrentGroup();
  const refreshCurrentGroup = useRefreshCurrentGroup();
  const loadGroupById = useLoadGroupById();
  const profile = useProfile();

  const [state, setState] = useState<string | undefined>(undefined);

  const [startDrawing, setStartDrawing] = useState(false);
  const [placeOrder, setPlaceOrder] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [ownerOfCurrentGroup, setOwnerOfCurrentGroup] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (myGroups?.length == 0) return setState("noGroup");

    if (currentGroup) {
      if (currentGroup.members.length < 2) {
        return setState("newGroupInfo");
      }

      if (currentGroup.activeDrawing) {
        return setState("activeDrawing");
      }
      if (
        !currentGroup.activeDrawing &&
        currentGroup.lastDrawing &&
        currentGroup.lastDrawing.endsInSeconds > -300 &&
        currentGroup.lastDrawing.who
      ) {
        return setState("result");
      }
      return setState("noActiveDrawing");
    }
    return setState(undefined);
  }, [myGroups, currentGroup]);

  // When mounted, load first group
  useEffect(() => {
    if (
      !myGroups ||
      myGroups.length == 0 ||
      !window.location.pathname.includes("/groups/")
    )
      return;
    const groupId = window.location.pathname.split("/").pop();
    if (!groupId) return;
    if (groupId == "default") {
      const idToLoad = myGroups[0].id;
      loadGroupById && loadGroupById(idToLoad);
      if (myGroups.length > 0) navigate(`/dashboard/groups/${idToLoad}`);
    } else {
      loadGroupById && loadGroupById(groupId);
    }
  }, []);

  useEffect(() => {
    const member = currentGroup?.members.find(
      (member) => member.user.id == profile?.id
    );
    const isOwner = member?.owner || false;
    setOwnerOfCurrentGroup(isOwner);
  }, [profile, currentGroup]);

  const handleCommitOrder = async (
    chosenDrink: string,
    text?: string,
    extras?: string[]
  ) => {
    if (!currentGroup || !currentGroup.activeDrawing) return;
    const extraString = extras?.join(", ");
    await placeOrderFunction(
      currentGroup.id,
      currentGroup.activeDrawing.id,
      chosenDrink,
      text,
      extraString
    );
    setPlaceOrder(false);
    refreshCurrentGroup && refreshCurrentGroup();
  };

  const myOrder = currentGroup?.activeDrawing?.orders.find(
    (order) => order.user.id == profile?.id
  );
  return (
    <>
      {startDrawing && <StartDrawing close={() => setStartDrawing(false)} />}

      {placeOrder && (
        <PlaceOrder
          close={() => setPlaceOrder(false)}
          placeOrder={handleCommitOrder}
          drinks={currentGroup?.drinks}
        />
      )}

      {showOrderDetails && (
        <OrderDetails close={() => setShowOrderDetails(false)} />
      )}
      <div className="flex flex-col flex-1 m-5 w-full">
        <div className="self-end flex gap-0">
          <BiLogoApple
            className="scale-80 xl:scale-100 xl:hover:scale-110 cursor-pointer"
            onClick={props.onGetApp}
          />
          <BiLogoAndroid
            className="scale-80 xl:scale-100 xl:hover:scale-110 cursor-pointer"
            onClick={props.onGetApp}
          />

          {ownerOfCurrentGroup && (
            <BiSolidCog
              className="scale-80 xl:scale-100 xl:hover:scale-110 cursor-pointer ml-5"
              onClick={props.onEditGroup}
              data-cy="groupSettingsButton"
            />
          )}
        </div>

        <div
          className="flex flex-col flex-1 mx-auto justify-center items-stretch xl:gap-10 h-full max-w-3xl"
          id="groupState"
        >
          {state == "activeDrawing" && currentGroup?.activeDrawing && (
            <ActiveDrawing
              self={currentGroup.activeDrawing.self}
              startedBy={currentGroup.activeDrawing.startedBy.name}
              seconds={currentGroup.activeDrawing.endsInSeconds}
              originalSeconds={currentGroup.activeDrawing.seconds}
              placedOrder={myOrder != undefined}
              handlePlaceOrder={() => setPlaceOrder(true)}
            />
          )}
          {state == "newGroupInfo" && <NewGroupInfo />}
          {state == "noActiveDrawing" && (
            <NoActiveDrawing setStartDrawing={() => setStartDrawing(true)} />
          )}
          {state == "noGroup" && (
            <NoGroup onCreateNewGroup={props.onCreateNewGroup} />
          )}
          {state == "result" && (
            <Result
              who={currentGroup?.lastDrawing?.who?.name || ""}
              self={currentGroup?.lastDrawing?.who?.id == profile?.id}
              startDrawing={() => setStartDrawing(true)}
              showOrderDetails={() => setShowOrderDetails(true)}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default ActiveGroup;
