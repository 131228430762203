import Sidebar from "./Sidebar/Sidebar";
import { useState } from "react";
import NewGroup from "./Modals/NewGroup/NewGroup";
import ActiveGroup from "./ActiveGroup/ActiveGroup";
import ProfileSettings from "./Modals/ProfileSettings/ProfileSettings";
import GroupSettings from "./Modals/GroupSettings/GroupSettings";
import GetApp from "./ActiveGroup/Modals/GetApp";
import { CurrentGroupProvider } from "../../../Context/CurrentGroup";
import Sse from "../../../Components/Sse";

const Dashboard: React.FC = () => {
  const [editProfileIsActive, setEditProfileIsActive] = useState(false);
  const [editGroupIsActive, setEditGroupIsActive] = useState(false);
  const [createNewGroupIsActive, setCreateNewGroupIsActive] = useState(false);
  const [getAppIsActive, setGetAppIsActive] = useState(false);
  return (
    <CurrentGroupProvider>
      <NewGroup onClose={() => setCreateNewGroupIsActive(false)} isOpen={createNewGroupIsActive} />
      <ProfileSettings onClose={() => setEditProfileIsActive(false)} isOpen={editProfileIsActive} />
      <GroupSettings onClose={() => setEditGroupIsActive(false)} isOpen={editGroupIsActive} />
      <GetApp onClose={() => setGetAppIsActive(false)} isOpen={getAppIsActive} />
      <Sse>
        <div className="flex h-svh fixed xl:static w-full">
          <Sidebar onCreateNewGroup={() => setCreateNewGroupIsActive(true)} onEditProfile={() => setEditProfileIsActive(true)} />

          <ActiveGroup
            onCreateNewGroup={() => setCreateNewGroupIsActive(true)}
            onEditGroup={() => setEditGroupIsActive(true)}
            onGetApp={() => setGetAppIsActive(true)}
          />
        </div>
      </Sse>
    </CurrentGroupProvider>
  );
};

export default Dashboard;
