import { Button } from "@nextui-org/react";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { together } from "../../../../assets/lottie";

const NoActiveDrawing: React.FC<{ setStartDrawing: () => void }> = (props) => {
  return (
    <>
      <Lottie loop animationData={together} play speed={0.1} segments={[34, 120]} style={{ maxHeight: 500, height: "60%" }} />
      <Button fullWidth color="primary" variant="solid" onPress={props.setStartDrawing}>
        Koffieronde starten
      </Button>
    </>
  );
};

export default NoActiveDrawing;
