import { DropdownTrigger, Dropdown, DropdownMenu, DropdownItem, DropdownSection, Avatar } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useCurrentGroup, useLoadGroupById } from "../../../../Context/CurrentGroup";
import { useMyGroups } from "../../../../Context/Profile";
import { BiChevronsDown, BiSolidAddToQueue } from "react-icons/bi";

type Props = { onCreateNewGroup: () => void };
const GroupMenu: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const currentGroup = useCurrentGroup();
  const myGroups = useMyGroups();
  const loadGroupById = useLoadGroupById();

  if (!myGroups) return;

  return (
    <Dropdown>
      <DropdownTrigger>
        <div className="bg-primary-200 flex items-center gap-2 p-2 cursor-pointer rounded-lg">
          <Avatar className="w-6 h-6 xl:h-10 xl:w-10" name={currentGroup?.name} />
          <span className="flex-1 hidden xl:block">{currentGroup?.name || "Selecteer groep"}</span>
          <BiChevronsDown />
        </div>
      </DropdownTrigger>

      {/* Use map because of TS issue https://github.com/nextui-org/nextui/issues/1691 */}
      <DropdownMenu aria-label="Dynamic Actions">
        <DropdownSection showDivider>
          {myGroups.map((group) => (
            <DropdownItem
              key={group.id}
              color="primary"
              startContent={<Avatar name={group.name} />}
              onPress={() => {
                loadGroupById && loadGroupById(group.id);
                navigate(`/dashboard/groups/${group.id}`);
              }}>
              {group.name}
            </DropdownItem>
          ))}
        </DropdownSection>
        <DropdownSection>
          <DropdownItem
            onPress={props.onCreateNewGroup}
            key="newGroup"
            color="primary"
            description="Maak een nieuwe groep aan"
            className="group"
            startContent={<BiSolidAddToQueue className="text-secondary-800 group-hover:text-secondary-100" size="2em" />}>
            Nieuwe groep
          </DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );
};

export default GroupMenu;
