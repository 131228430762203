import {
  Switch,
  Tab,
  Tabs,
  Button,
  Input,
  Accordion,
  AccordionItem,
} from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "@nextui-org/modal";
import { useEffect, useState } from "react";
import Emojis from "../../../../../Components/Emojis";
import {
  useGetProfileFunction,
  useMyGroups,
  useProfile,
  useUpdateProfileFunction,
} from "../../../../../Context/Profile";
import { toggleNotificationFunction } from "../../../../../utils/group";
import { BiRename, BiNotification } from "react-icons/bi";

type Props = { onClose: () => void; isOpen: boolean };

const ProfileSettings: React.FC<Props> = (props) => {
  // Context
  const profile = useProfile();
  const myGroups = useMyGroups();
  const getProfileFunction = useGetProfileFunction();
  const updateProfileFunction = useUpdateProfileFunction();

  // States
  const [blade, setBlade] = useState("profile");
  const [valide, setValide] = useState(false);
  // States: profile
  const [newName, setName] = useState(profile?.name);
  const [newAvatar, setAvatar] = useState(profile?.avatar);

  // Effects
  // Effects: set valide to false when blade changes
  useEffect(() => {
    setValide(false);
  }, [blade]);
  // Effects: check if the form is valid
  useEffect(() => {
    const r = /^[a-z\s]+$/gi;
    const v =
      blade == "profile" &&
      newName != undefined &&
      newName.length > 2 &&
      r.test(newName) &&
      newName.length < 20 &&
      newAvatar != undefined &&
      newAvatar.length > 0 &&
      (newName != profile?.name || newAvatar != profile.avatar);
    setValide(v);
  }, [newName, newAvatar, blade]);

  // Functions
  // Functions: handle confirm
  const handleConfirm = async () => {
    if (blade == "profile") {
      if (newName == "bug") throw new Error("Bug test");
      updateProfileFunction &&
        (await updateProfileFunction({ name: newName, avatar: newAvatar }));
      setValide(false);
    }
  };

  const handleToggleNotification = async (
    groupId: string,
    method: string,
    value: boolean
  ) => {
    await toggleNotificationFunction(groupId, method, value);
    getProfileFunction && (await getProfileFunction());
  };

  // Component
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      scrollBehavior="outside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Profielinstellingen
            </ModalHeader>
            <ModalBody>
              <div className="flex w-full flex-col">
                <Tabs
                  aria-label="Options"
                  color="primary"
                  radius="lg"
                  selectedKey={blade}
                  onSelectionChange={(k) => setBlade(k.toString())}
                  fullWidth
                >
                  <Tab
                    key="profile"
                    className="group"
                    title={
                      <div className="flex items-center space-x-2">
                        <BiRename
                          size="1.4em"
                          className="group-aria-selected:text-white"
                        />
                        <span>Mijn gegevens</span>
                      </div>
                    }
                  >
                    <div className="grid gap-6">
                      <Input
                        type="text"
                        label="Naam"
                        onChange={(e) => setName(e.target.value)}
                        value={newName}
                      />
                      <Emojis
                        current={newAvatar}
                        onClick={(filename) => setAvatar(filename)}
                      />
                    </div>
                  </Tab>
                  <Tab
                    key="notifications"
                    className="group"
                    title={
                      <div className="flex items-center space-x-2">
                        <BiNotification
                          size="1.4em"
                          className="group-aria-selected:text-white"
                        />
                        <span>Notificaties</span>
                      </div>
                    }
                  >
                    <Accordion>
                      // @ts-ignore
                      {myGroups?.map((group) => {
                        return (
                          <AccordionItem title={group.name} key={group.id}>
                            <div className="flex justify-around">
                              <Switch
                                defaultSelected
                                isSelected={group.emailNotification}
                                onValueChange={(value) =>
                                  handleToggleNotification(
                                    group.id,
                                    "email",
                                    value
                                  )
                                }
                                aria-label="emailNotification"
                              >
                                E-mail
                              </Switch>
                              <Switch
                                defaultSelected
                                isSelected={group.pushNotification}
                                onValueChange={(value) =>
                                  handleToggleNotification(
                                    group.id,
                                    "push",
                                    value
                                  )
                                }
                                aria-label="pushNotification"
                              >
                                Pushnotificatie
                              </Switch>
                            </div>
                          </AccordionItem>
                        );
                      })}
                    </Accordion>
                  </Tab>
                </Tabs>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Sluiten
              </Button>
              <Button
                color="primary"
                onPress={handleConfirm}
                isDisabled={!valide}
              >
                Opslaan
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ProfileSettings;
