import { useEffect, useRef, useState } from "react";
import { useGetProfileFunction, useProfile } from "../Context/Profile";
import { useAddOnlineMember, useCurrentGroupId, useLoadGroupById, useRemoveOnlineMember } from "../Context/CurrentGroup";

type Props = {
  children: React.ReactNode;
};

const Sse: React.FC<Props> = (props) => {
  const [sseInit, setSseInit] = useState(false);
  const eventSource = useRef<EventSource | undefined>();
  const eventSourceTimer = useRef<any>();
  const profile = useProfile();
  const loadGroupById = useLoadGroupById();
  const getProfileFunction = useGetProfileFunction();
  const addOnlineMember = useAddOnlineMember();
  const removeOnlineMember = useRemoveOnlineMember();
  const currentGroupId = useCurrentGroupId();

  useEffect(() => {
    if (!sseInit && profile) {
      setSseInit(true);
      eventSource.current = new EventSource(`${import.meta.env.VITE_MESSENGER_URL}/subscribe/${profile.id}`);
      eventSourceTimer.current = setInterval(() => {
        if (eventSource.current?.readyState != 1) {
          // eventsource not ready, reconnect
          eventSource.current = new EventSource(`${import.meta.env.VITE_MESSENGER_URL}/subscribe/${profile.id}`);
        }
      }, 10000);
    } else if (!profile && sseInit) {
      // User loggeg out
      eventSource.current?.close();
    }
  }, [profile?.id]);

  useEffect(() => {
    if (eventSource.current && currentGroupId) {
      eventSource.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        const { subject, body } = message;
        if (subject == "user-online") addOnlineMember && addOnlineMember(body);
        if (subject == "user-offline") removeOnlineMember && removeOnlineMember(body);
        if (subject == "refresh-group") loadGroupById && currentGroupId && loadGroupById(currentGroupId);
        if (subject == "refresh-profile") getProfileFunction && getProfileFunction();
      };
    }
  }, [currentGroupId]);

  return props.children;
};

export default Sse;
