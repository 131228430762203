import { useCallback, useState } from "react";
import * as Api from "../api-sdk";
import { getAccessToken } from "../utils/cognito";
import { useContextSelector, createContext } from "use-context-selector";
import * as Sentry from "@sentry/react";

const configuration = new Api.Configuration({
  basePath: import.meta.env.VITE_API_PATH,
});

const useStore = () => {
  const [profile, setProfile] = useState<Api.MyProfile | undefined>(undefined);
  const [myGroups, setMyGroups] = useState<Api.MyGroups[]>([]);

  // Get profile
  const getProfileFunction = async () => {
    try {
      const accessToken = await getAccessToken();
      if (!accessToken)
        throw new Error("Failed to get access token while loading profile");
      const usersApi = new Api.UsersApi(configuration);
      const user = await usersApi.getProfile({ accessToken });
      setProfile(user.profile);
      setMyGroups(user.groups);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  // Funtions
  const updateProfileFunction = async (options: {
    name?: string;
    avatar?: string;
    InitSetup?: boolean;
    verificationCode?: string;
  }) => {
    try {
      const accessToken = await getAccessToken();
      if (!accessToken)
        throw new Error("Failed to get access token while updating profile");
      const { name, avatar } = options;
      const isInitSetup = options.InitSetup || false;
      const usersApi = new Api.UsersApi(configuration);
      const code = Number(options.verificationCode) || undefined;
      await usersApi.updateUser({
        accessToken,
        userBody: { name, avatar: avatar, isInitSetup, verificationCode: code },
      });
      await getProfileFunction();
      return true;
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
  };

  return {
    profile,
    myGroups,
    getProfileFunction: useCallback(getProfileFunction, []),
    updateProfileFunction: useCallback(updateProfileFunction, []),
  };
};

// Context
interface IProfileContext {
  profile?: Api.MyProfile;
  myGroups: Api.MyGroups[];
  getProfileFunction: () => Promise<void>;
  updateProfileFunction: (options: {
    name?: string;
    avatar?: string;
    InitSetup?: boolean;
    verificationCode?: string;
  }) => Promise<boolean>;
}

export const ProfileContext = createContext<IProfileContext | undefined>(
  undefined
);

type Props = {
  children: React.ReactNode;
};

export const ProfileProvider = (props: Props) => (
  <ProfileContext.Provider value={useStore()}>
    {props.children}
  </ProfileContext.Provider>
);

export const useProfile = () =>
  useContextSelector(ProfileContext, (s) => s?.profile);
export const useMyGroups = () =>
  useContextSelector(ProfileContext, (s) => s?.myGroups);
export const useGetProfileFunction = () =>
  useContextSelector(ProfileContext, (s) => s?.getProfileFunction);
export const useUpdateProfileFunction = () =>
  useContextSelector(ProfileContext, (s) => s?.updateProfileFunction);
