/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddMemberBody,
  UpdateMemberBody,
} from '../models/index';
import {
    AddMemberBodyFromJSON,
    AddMemberBodyToJSON,
    UpdateMemberBodyFromJSON,
    UpdateMemberBodyToJSON,
} from '../models/index';

export interface AddMemberRequest {
    accessToken: string;
    id: string;
    addMemberBody?: AddMemberBody;
}

export interface DeleteInviteRequest {
    accessToken: string;
    id: string;
    inviteId: string;
}

export interface DeleteMemberRequest {
    accessToken: string;
    id: string;
    userId: string;
}

export interface GetOnlineUsersRequest {
    accessToken: string;
    id: string;
}

export interface UpdateMemberRequest {
    accessToken: string;
    id: string;
    userId: string;
    updateMemberBody?: UpdateMemberBody;
}

/**
 * 
 */
export class GroupMembersApi extends runtime.BaseAPI {

    /**
     * Add member
     */
    async addMemberRaw(requestParameters: AddMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling addMember().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/members`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMemberBodyToJSON(requestParameters['addMemberBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add member
     */
    async addMember(requestParameters: AddMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Delete invite from group
     */
    async deleteInviteRaw(requestParameters: DeleteInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling deleteInvite().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteInvite().'
            );
        }

        if (requestParameters['inviteId'] == null) {
            throw new runtime.RequiredError(
                'inviteId',
                'Required parameter "inviteId" was null or undefined when calling deleteInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/invites/{inviteId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters['inviteId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete invite from group
     */
    async deleteInvite(requestParameters: DeleteInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Delete member
     */
    async deleteMemberRaw(requestParameters: DeleteMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling deleteMember().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteMember().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling deleteMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/members/{userId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete member
     */
    async deleteMember(requestParameters: DeleteMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Get online users
     */
    async getOnlineUsersRaw(requestParameters: GetOnlineUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling getOnlineUsers().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOnlineUsers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/members`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get online users
     */
    async getOnlineUsers(requestParameters: GetOnlineUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getOnlineUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update member
     */
    async updateMemberRaw(requestParameters: UpdateMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling updateMember().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateMember().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling updateMember().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/members/{userId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMemberBodyToJSON(requestParameters['updateMemberBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update member
     */
    async updateMember(requestParameters: UpdateMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMemberRaw(requestParameters, initOverrides);
    }

}
