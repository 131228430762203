/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Drink
 */
export interface Drink {
    /**
     * 
     * @type {string}
     * @memberof Drink
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Drink
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Drink
     */
    icon: string;
    /**
     * 
     * @type {boolean}
     * @memberof Drink
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof Drink
     */
    extras?: string;
}

/**
 * Check if a given object implements the Drink interface.
 */
export function instanceOfDrink(value: object): value is Drink {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('icon' in value) || value['icon'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    return true;
}

export function DrinkFromJSON(json: any): Drink {
    return DrinkFromJSONTyped(json, false);
}

export function DrinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Drink {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['_id'],
        'name': json['name'],
        'icon': json['icon'],
        'active': json['active'],
        'extras': json['extras'] == null ? undefined : json['extras'],
    };
}

export function DrinkToJSON(json: any): Drink {
    return DrinkToJSONTyped(json, false);
}

export function DrinkToJSONTyped(value?: Drink | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        '_id': value['id'],
        'name': value['name'],
        'icon': value['icon'],
        'active': value['active'],
        'extras': value['extras'],
    };
}

