/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddMemberBody
 */
export interface AddMemberBody {
    /**
     * 
     * @type {string}
     * @memberof AddMemberBody
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddMemberBody
     */
    invite: boolean;
}

/**
 * Check if a given object implements the AddMemberBody interface.
 */
export function instanceOfAddMemberBody(value: object): value is AddMemberBody {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('invite' in value) || value['invite'] === undefined) return false;
    return true;
}

export function AddMemberBodyFromJSON(json: any): AddMemberBody {
    return AddMemberBodyFromJSONTyped(json, false);
}

export function AddMemberBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddMemberBody {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'invite': json['invite'],
    };
}

export function AddMemberBodyToJSON(json: any): AddMemberBody {
    return AddMemberBodyToJSONTyped(json, false);
}

export function AddMemberBodyToJSONTyped(value?: AddMemberBody | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'invite': value['invite'],
    };
}

