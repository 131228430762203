import FullCenter from "./FullCenter";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { coffeeMachine } from "../../assets/lottie";
import { Alert, CircularProgress } from "@nextui-org/react";

const Loading: React.FC = () => {
  return (
    <FullCenter>
      <div className="max-w-screen-md min-w-64 w-1/2">
        <Lottie loop animationData={coffeeMachine} play speed={0.1} segments={[34, 120]} className="mb-10" />
        <Alert description="Wiegaaterkoffiehalen wordt geladen" title="Bijna koffietijd!" color="primary" />
        <CircularProgress aria-label="Even geduld" color="primary" className="mt-5 mx-auto" />
      </div>
    </FullCenter>
  );
};

export default Loading;
