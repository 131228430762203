/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Invite
 */
export interface Invite {
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    sendBy: string;
}

/**
 * Check if a given object implements the Invite interface.
 */
export function instanceOfInvite(value: object): value is Invite {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('sendBy' in value) || value['sendBy'] === undefined) return false;
    return true;
}

export function InviteFromJSON(json: any): Invite {
    return InviteFromJSONTyped(json, false);
}

export function InviteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invite {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'sendBy': json['sendBy'],
    };
}

export function InviteToJSON(json: any): Invite {
    return InviteToJSONTyped(json, false);
}

export function InviteToJSONTyped(value?: Invite | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'sendBy': value['sendBy'],
    };
}

