/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateMemberBody
 */
export interface UpdateMemberBody {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMemberBody
     */
    isOwner: boolean;
}

/**
 * Check if a given object implements the UpdateMemberBody interface.
 */
export function instanceOfUpdateMemberBody(value: object): value is UpdateMemberBody {
    if (!('isOwner' in value) || value['isOwner'] === undefined) return false;
    return true;
}

export function UpdateMemberBodyFromJSON(json: any): UpdateMemberBody {
    return UpdateMemberBodyFromJSONTyped(json, false);
}

export function UpdateMemberBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMemberBody {
    if (json == null) {
        return json;
    }
    return {
        
        'isOwner': json['isOwner'],
    };
}

export function UpdateMemberBodyToJSON(json: any): UpdateMemberBody {
    return UpdateMemberBodyToJSONTyped(json, false);
}

export function UpdateMemberBodyToJSONTyped(value?: UpdateMemberBody | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'isOwner': value['isOwner'],
    };
}

