/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
    OrderToJSONTyped,
} from './Order';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
    UserProfileToJSONTyped,
} from './UserProfile';

/**
 * 
 * @export
 * @interface Drawing
 */
export interface Drawing {
    /**
     * 
     * @type {string}
     * @memberof Drawing
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Drawing
     */
    group: string;
    /**
     * 
     * @type {string}
     * @memberof Drawing
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof Drawing
     */
    end: string;
    /**
     * 
     * @type {UserProfile}
     * @memberof Drawing
     */
    startedBy: UserProfile;
    /**
     * 
     * @type {number}
     * @memberof Drawing
     */
    seconds: number;
    /**
     * 
     * @type {boolean}
     * @memberof Drawing
     */
    self: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Drawing
     */
    open: boolean;
    /**
     * 
     * @type {Array<Order>}
     * @memberof Drawing
     */
    orders: Array<Order>;
    /**
     * 
     * @type {number}
     * @memberof Drawing
     */
    endsInSeconds: number;
    /**
     * 
     * @type {UserProfile}
     * @memberof Drawing
     */
    who?: UserProfile;
}

/**
 * Check if a given object implements the Drawing interface.
 */
export function instanceOfDrawing(value: object): value is Drawing {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('group' in value) || value['group'] === undefined) return false;
    if (!('start' in value) || value['start'] === undefined) return false;
    if (!('end' in value) || value['end'] === undefined) return false;
    if (!('startedBy' in value) || value['startedBy'] === undefined) return false;
    if (!('seconds' in value) || value['seconds'] === undefined) return false;
    if (!('self' in value) || value['self'] === undefined) return false;
    if (!('open' in value) || value['open'] === undefined) return false;
    if (!('orders' in value) || value['orders'] === undefined) return false;
    if (!('endsInSeconds' in value) || value['endsInSeconds'] === undefined) return false;
    return true;
}

export function DrawingFromJSON(json: any): Drawing {
    return DrawingFromJSONTyped(json, false);
}

export function DrawingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Drawing {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['_id'],
        'group': json['group'],
        'start': json['start'],
        'end': json['end'],
        'startedBy': UserProfileFromJSON(json['startedBy']),
        'seconds': json['seconds'],
        'self': json['self'],
        'open': json['open'],
        'orders': ((json['orders'] as Array<any>).map(OrderFromJSON)),
        'endsInSeconds': json['EndsInSeconds'],
        'who': json['who'] == null ? undefined : UserProfileFromJSON(json['who']),
    };
}

export function DrawingToJSON(json: any): Drawing {
    return DrawingToJSONTyped(json, false);
}

export function DrawingToJSONTyped(value?: Drawing | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        '_id': value['id'],
        'group': value['group'],
        'start': value['start'],
        'end': value['end'],
        'startedBy': UserProfileToJSON(value['startedBy']),
        'seconds': value['seconds'],
        'self': value['self'],
        'open': value['open'],
        'orders': ((value['orders'] as Array<any>).map(OrderToJSON)),
        'EndsInSeconds': value['endsInSeconds'],
        'who': UserProfileToJSON(value['who']),
    };
}

