import { Button, Image, Tooltip } from "@nextui-org/react";
import { Fragment, useEffect, useState } from "react";
import { Drink as IDrink } from "../../../../../../api-sdk";
import coffeeIcons from "../../../../../../assets/coffee-icons";
import EditDrinkModal from "./EditDrinkModal";
import RemoveDrinkModal from "./RemoveDrinkModal";
import {
  useCurrentGroup,
  useRefreshCurrentGroup,
} from "../../../../../../Context/CurrentGroup";
import {
  createDrinkFunction,
  deleteDrinkFunction,
  updateDrinkFunction,
} from "../../../../../../utils/group";
import { BiEdit, BiTrash } from "react-icons/bi";

const Drinks: React.FC = () => {
  // Context
  const currentGroup = useCurrentGroup();
  const refreshCurrentGroup = useRefreshCurrentGroup();
  // States
  const [drinkToRemove, setDrinkToRemove] = useState<string | undefined>(
    undefined
  );
  const [editingDrink, setEditingDrink] = useState<IDrink | undefined>(
    undefined
  );
  const [addingNewDrink, setAddingNewDrink] = useState(false);
  const [drinksMax, setDrinksMax] = useState(false);
  // Effects
  useEffect(() => {
    if (!currentGroup || !currentGroup.drinks) return;
    if (currentGroup.drinks.filter((d) => d.active).length >= 10)
      setDrinksMax(true);
    else setDrinksMax(false);
  }, [currentGroup?.drinks]);

  // Functions
  const closeAddDrinkModal = () => {
    setEditingDrink(undefined);
    setAddingNewDrink(false);
  };

  const handleEditDrink = (id: string) => {
    if (!currentGroup?.drinks) return;
    const drink = currentGroup?.drinks.find((d) => d.id == id);
    setEditingDrink(drink);
  };

  const handleUpdateDrink = async (
    name: string,
    icon: string,
    extras: string
  ) => {
    if (!currentGroup) return;
    if (!editingDrink?.id) {
      // Create new drink
      await createDrinkFunction(currentGroup.id, name, icon, extras);
    } else {
      await updateDrinkFunction(
        currentGroup.id,
        editingDrink.id,
        name,
        icon,
        extras
      );
    }
    refreshCurrentGroup && refreshCurrentGroup();
    closeAddDrinkModal();
  };

  const handleRemoveDrink = async () => {
    if (!currentGroup) return;
    if (drinkToRemove)
      await deleteDrinkFunction(currentGroup.id, drinkToRemove);
    setDrinkToRemove(undefined);
    refreshCurrentGroup && refreshCurrentGroup();
  };

  return (
    <>
      {(editingDrink || addingNewDrink) && (
        <EditDrinkModal
          close={closeAddDrinkModal}
          addDrink={handleUpdateDrink}
          editingDrink={editingDrink}
        />
      )}
      {drinkToRemove && currentGroup?.drinks && (
        <RemoveDrinkModal
          removeDrink={
            currentGroup.drinks.find((d) => d.id == drinkToRemove)?.name
          }
          onClose={() => setDrinkToRemove(undefined)}
          onConfirm={handleRemoveDrink}
        />
      )}
      <div
        data-cy="groupSettingsDrinkItems"
        className="flex flex-col gap-3 mt-3"
      >
        {currentGroup?.drinks &&
          currentGroup.drinks.map((drink) => {
            const iconIsValid = Object.prototype.hasOwnProperty.call(
              coffeeIcons,
              drink.icon
            );
            const icon = iconIsValid
              ? coffeeIcons[drink.icon]
              : coffeeIcons.coffeeCup;
            if (drink.active)
              return (
                <Fragment key={drink.id}>
                  <div className="flex gap-1 items-center">
                    <Image src={icon} alt={drink.name} width={32} height={32} />
                    <span className="flex-1">{drink.name}</span>
                    <BiEdit
                      data-cy="groupSettingsDrinkItemsEdit"
                      size="1.4em"
                      className="hover:scale-125"
                      onClick={() => handleEditDrink(drink.id)}
                    />
                    <BiTrash
                      data-cy="groupSettingsDrinkItemsRemove"
                      size="1.4em"
                      className="scale-90 hover:scale-105 text-danger-LIGHT hover:text-danger"
                      onClick={() => setDrinkToRemove(drink.id)}
                    />
                  </div>
                  <hr />
                </Fragment>
              );
          })}
      </div>
      <Tooltip
        content="Maximaal 10 drankjes per groep"
        color="danger"
        isDisabled={!drinksMax}
        placement="bottom"
      >
        <span>
          <Button
            color="primary"
            fullWidth
            onPress={() => setAddingNewDrink(true)}
            isDisabled={drinksMax}
          >
            Nieuw drankje toevoegen
          </Button>
        </span>
      </Tooltip>
    </>
  );
};

export default Drinks;
