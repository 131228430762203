/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddDrinkBody
 */
export interface AddDrinkBody {
    /**
     * 
     * @type {string}
     * @memberof AddDrinkBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddDrinkBody
     */
    icon: string;
    /**
     * 
     * @type {string}
     * @memberof AddDrinkBody
     */
    extras: string;
}

/**
 * Check if a given object implements the AddDrinkBody interface.
 */
export function instanceOfAddDrinkBody(value: object): value is AddDrinkBody {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('icon' in value) || value['icon'] === undefined) return false;
    if (!('extras' in value) || value['extras'] === undefined) return false;
    return true;
}

export function AddDrinkBodyFromJSON(json: any): AddDrinkBody {
    return AddDrinkBodyFromJSONTyped(json, false);
}

export function AddDrinkBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddDrinkBody {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'icon': json['icon'],
        'extras': json['extras'],
    };
}

export function AddDrinkBodyToJSON(json: any): AddDrinkBody {
    return AddDrinkBodyToJSONTyped(json, false);
}

export function AddDrinkBodyToJSONTyped(value?: AddDrinkBody | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'icon': value['icon'],
        'extras': value['extras'],
    };
}

