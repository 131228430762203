import { useState, useEffect } from "react";
import { Button, Tabs, Tab } from "@nextui-org/react";
import { Modal, ModalContent, ModalBody, ModalHeader, ModalFooter } from "@nextui-org/modal";
import { BiRename, BiUser, BiCoffee, BiTrash } from "react-icons/bi";

// components
import Group from "./Group/Group";
import Drinks from "./Drinks/Drinks";
import Members from "./Members/Members";
import RemoveGroup from "./RemoveGroup/RemoveGroup";

type Props = { onClose: () => void; isOpen: boolean };

const GroupSettings: React.FC<Props> = (props) => {
  // States
  const [blade, setBlade] = useState("group");
  const [valide, setValide] = useState(false);
  const [confirmDispatched, setConfirmDispatched] = useState(false);

  // Effects
  // Effects: set valide to false when blade changes
  useEffect(() => {
    setValide(false);
  }, [blade]);

  // Functions
  // Functions: handle confirm
  const handleConfirm = async () => {
    setConfirmDispatched(true);
  };

  const resetConfirmDispatched = () => {
    setConfirmDispatched(false);
    setValide(false);
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">Groepsinstellingen</ModalHeader>
            <ModalBody>
              <div className="flex w-full flex-col">
                <Tabs
                  aria-label="Options"
                  color="primary"
                  radius="lg"
                  selectedKey={blade}
                  onSelectionChange={(k) => setBlade(k.toString())}
                  fullWidth>
                  <Tab
                    key="group"
                    className="group"
                    data-cy="groupSettingsTabGeneral"
                    title={
                      <div className="flex items-center space-x-2">
                        <BiRename className="text-primary group-aria-selected:text-white" size="1.2em" />
                        <span>Groep</span>
                      </div>
                    }>
                    <Group updateValide={setValide} confirmDispatched={confirmDispatched} resetConfirmDispatched={resetConfirmDispatched} />
                  </Tab>
                  <Tab
                    key="drinks"
                    className="group"
                    data-cy="groupSettingsTabDrinks"
                    title={
                      <div className="flex items-center space-x-2">
                        <BiCoffee className="text-primary group-aria-selected:text-white" size="1.2em" />
                        <span>Drankjes</span>
                      </div>
                    }>
                    <Drinks />
                  </Tab>
                  <Tab
                    key="members"
                    className="group"
                    data-cy="groupSettingsTabMembers"
                    title={
                      <div className="flex items-center space-x-2">
                        <BiUser className="text-primary group-aria-selected:text-white" size="1.2em" />
                        <span>Leden</span>
                      </div>
                    }>
                    <Members />
                  </Tab>
                  <Tab
                    key="remove"
                    data-cy="groupSettingsTabRemove"
                    className="group"
                    title={
                      <div className="flex items-center space-x-2 ">
                        <BiTrash className="text-primary group-aria-selected:text-white" size="1.2em" />
                        <span>Verwijderen</span>
                      </div>
                    }>
                    <RemoveGroup onClose={props.onClose} />
                  </Tab>
                </Tabs>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button data-cy="groupSettingsClose" color="danger" variant="light" onPress={onClose}>
                Sluiten
              </Button>
              <Button data-cy="groupSettingsSave" color="primary" onPress={handleConfirm} isDisabled={!valide}>
                Opslaan
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default GroupSettings;
