import { useState, useEffect } from "react";
import { Alert, Card, CardBody } from "@nextui-org/react";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
// Context
import {
  useEmailOfCurrentUser,
  useSignInCurrentUser,
} from "../../Context/Auth";
// Components
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ResetPasswordScreen1 from "./screens/ResetPasswordScreen1";
import ResetPasswordScreen2 from "./screens/ResetPasswordScreen2";
// Illustations
import { boyGirlHoldingCoffee } from "../../assets/lottie";

import * as Api from "../../api-sdk";
import { useNavigate } from "react-router-dom";
import {
  requestCode,
  resetPassword,
  signIn,
  signUp,
} from "../../utils/cognito";
import { captureException } from "@sentry/react";

const Login: React.FC = () => {
  const navigate = useNavigate();
  // Load auth context
  const emailOfCurrentUser = useEmailOfCurrentUser();
  const signInCurrentUser = useSignInCurrentUser();
  // Set states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [formOk, setFormOk] = useState(false);
  const [mode, setMode] = useState<"login" | "register" | "reset1" | "reset2">(
    "login"
  );
  const [invite, setInvite] = useState<Api.Invite | null>(null);
  const [authenticationError, setAuthenticationError] = useState<string | null>(
    null
  );
  const [authenticationMessage, setAuthenticationMessage] = useState<
    string | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchInvite = async (id: string) => {
    const configuration = new Api.Configuration({
      basePath: import.meta.env.VITE_API_PATH,
    });
    const inviteApi = new Api.InviteApi(configuration);
    try {
      const invite = await inviteApi.getInvite({ inviteId: id });
      setInvite(invite);
      setEmail(invite.email);
      setMode("register");
    } catch (e) {
      captureException(e);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("invite");
    if (id) fetchInvite(id);
  }, []);

  // On typing: Check if input fields are correct and clear any previous errors
  useEffect(() => {
    setAuthenticationError(null);
    const emailValid = email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (
      mode == "register" &&
      emailValid &&
      email.length > 6 &&
      password.length > 6 &&
      password == password2
    )
      setFormOk(true);
    else if (
      mode == "login" &&
      emailValid &&
      email.length > 6 &&
      password.length > 6
    )
      setFormOk(true);
    else if (mode == "reset1" && emailValid && email.length > 6)
      setFormOk(true);
    else if (
      mode == "reset2" &&
      verificationCode.length > 3 &&
      password.length > 6 &&
      password == password2
    )
      setFormOk(true);
    else setFormOk(false);
  }, [email, password, password2, mode, verificationCode]);

  // When switching mode, clear all states (except email)
  useEffect(() => {
    setPassword("");
    setPassword2("");
    setVerificationCode("");
  }, [mode]);

  const handleSignIn = async () => {
    if (loading || !formOk) return;
    try {
      setAuthenticationMessage(null);
      setLoading(true);
      const signInSucceeded = await signIn(email, password);
      if (!signInSucceeded) throw new Error();
      handleSignInCurrentUser();
    } catch (e) {
      setAuthenticationError("Ongeldige inloggegevens");
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    if (loading || !formOk) return;
    try {
      setAuthenticationMessage(null);
      setLoading(true);
      const signUpSucceeded = await signUp(email, password);
      if (!signUpSucceeded) throw new Error();
      const signInSucceeded = await signIn(email, password);
      if (!signInSucceeded) throw new Error();
      handleSignInCurrentUser();
    } catch (e) {
      if (typeof e == "string") setAuthenticationError(e);
      else
        setAuthenticationError(
          "Er is iets fout gegaan, probeer het later nogmaals of neem contact met ons op"
        );
    } finally {
      setLoading(false);
    }
  };

  const handleRequestCode = async () => {
    if (loading || !formOk) return;
    setLoading(true);
    const succeeded = await requestCode(email);
    if (!succeeded) {
      setAuthenticationError("Er is iets fout gegaan");
      return;
    } else {
      setMode("reset2");
    }
    setLoading(false);
  };

  const handleResetPassword = async () => {
    if (loading || !formOk) return;
    setLoading(true);
    const succeeded = await resetPassword(email, verificationCode, password);
    if (!succeeded) {
      setAuthenticationError("Er is iets fout gegaan");
    } else {
      setAuthenticationMessage(
        "Wachtwoord is aangepast. Je kan nu weer inloggen."
      );
      setMode("login");
    }
    setLoading(false);
  };

  const handleSignInCurrentUser = async () => {
    if (!signInCurrentUser) return;
    const succeeded = await signInCurrentUser();
    if (succeeded) {
      const urlParams = new URLSearchParams(window.location.search);
      const redirectToGroup = urlParams.get("redirectToGroup");
      if (redirectToGroup) navigate(`/dashboard/groups/${redirectToGroup}`);
      else navigate("/dashboard/groups/default");
    } else {
      setLoading(true);
    }
  };

  return (
    <div className="flex h-full align-items-center">
      <div className="flex w-11/12 xl:w-auto max-w-7xl items-center m-auto">
        <Card className="h-min w-full xl:min-w-[780px] p-8">
          <CardBody>
            <div
              className="flex flex-col gap-4 items-center flex-1"
              id="loginSecond"
            >
              <Lottie
                loop
                animationData={boyGirlHoldingCoffee}
                play
                speed={0.1}
                segments={[34, 120]}
                className="w-80"
              />
              {authenticationError && (
                <Alert color="danger" title={authenticationError} />
              )}
              {authenticationMessage && (
                <Alert color="warning" title={authenticationMessage} />
              )}

              {mode == "login" && (
                <LoginScreen
                  onForgotPassword={() => setMode("reset1")}
                  onSignInCurrentUser={handleSignInCurrentUser}
                  isLoading={loading}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  onRegister={() => setMode("register")}
                  emailOfCurrentUser={emailOfCurrentUser || ""}
                  onSignIn={handleSignIn}
                  formIsValid={formOk}
                />
              )}
              {mode == "register" && (
                <RegisterScreen
                  isLoading={loading}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  formIsValid={formOk}
                  confirmPassword={password2}
                  inviteSendBy={invite?.sendBy || null}
                  setConfirmPassword={setPassword2}
                  onSignUp={handleSignUp}
                  onSignin={() => setMode("login")}
                />
              )}
              {mode == "reset1" && (
                <ResetPasswordScreen1
                  email={email}
                  setEmail={setEmail}
                  onSubmit={handleRequestCode}
                  isLoading={loading}
                  formIsValid={formOk}
                  onSignin={() => setMode("login")}
                />
              )}
              {mode == "reset2" && (
                <ResetPasswordScreen2
                  onSignin={() => setMode("login")}
                  setPassword={setPassword}
                  setConfirmPassword={setPassword2}
                  confirmPassword={password2}
                  setVerificationCode={setVerificationCode}
                  verificationCode={verificationCode}
                  isLoading={loading}
                  password={password}
                  formIsValid={formOk}
                  onSubmit={handleResetPassword}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Login;
