import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { group } from "../../../../assets/lottie";
import { Alert } from "@nextui-org/react";

const NewGroupInfo: React.FC = () => {
  return (
    <>
      <Lottie loop animationData={group} play speed={0.1} segments={[34, 120]} style={{ maxHeight: 500, height: "60%" }} />
      <div className="mb-4">
        <Alert
          color="primary"
          variant="bordered"
          description={"Voeg om te beginnen enkele collega's toe via de groepsinstellingen rechtsbovenin het scherm"}
          title={"Geweldig, je hebt een nieuwe groep aangemaakt"}
        />
      </div>
    </>
  );
};

export default NewGroupInfo;
