import { Switch, Button } from "@nextui-org/react";
import { useState } from "react";
import { deleteGroupFunction } from "../../../../../../utils/group";
import {
  useCurrentGroup,
  useLoadGroupById,
  useRefreshCurrentGroup,
} from "../../../../../../Context/CurrentGroup";
import { useNavigate } from "react-router-dom";
import { useMyGroups } from "../../../../../../Context/Profile";

type Props = { onClose: () => void };

const RemoveGroupBlade: React.FC<Props> = (props) => {
  const [confirm, setConfirm] = useState(false);
  const refreshCurrentGroup = useRefreshCurrentGroup();
  const currentGroup = useCurrentGroup();
  const loadGroupById = useLoadGroupById();
  const myGroups = useMyGroups();

  const navigate = useNavigate();

  const handleDeleteGroup = async () => {
    if (!currentGroup) return;
    await deleteGroupFunction(currentGroup.id);
    refreshCurrentGroup && (await refreshCurrentGroup());
    // Navigate back to availile group
    const idToLoad =
      myGroups && myGroups.length > 0 ? myGroups[0].id : undefined;

    idToLoad && loadGroupById && loadGroupById(idToLoad);
    idToLoad && navigate("/dashboard/groups/default");
    props.onClose();
  };

  if (currentGroup?.active) {
    return (
      <div className="grid gap-6">
        <p>
          Als je deze groep verwijdert dan is de groep nog zichtbaar bij
          gebruikers totdat ze opnieuw hebben ingelogd. Eventuele lopende rondes
          worden nog afgemaakt.
        </p>
        <Switch
          data-cy="removeGroupSwitch"
          isSelected={confirm}
          onValueChange={setConfirm}
          color="danger"
        >
          Ik weet zeker dat ik de groep{" "}
          <span className="font-semibold">{currentGroup.name}</span> wil
          verwijderen
        </Switch>
        <Button
          data-cy="removeGroupConfirm"
          color="danger"
          variant="bordered"
          isDisabled={!confirm}
          onPress={handleDeleteGroup}
        >
          Groep verwijderen
        </Button>
      </div>
    );
  } else {
    return (
      <>
        <p className="font-semibold">Groep verwijderen</p>
        <hr />
        <div className="grid gap-6">
          <p>
            Deze groep is verwijderd. De volgende keer als je inlogd zal de
            groep niet meer zichtbaar zijn
          </p>
        </div>
      </>
    );
  }
};

export default RemoveGroupBlade;
