/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserBody
 */
export interface UserBody {
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    avatar?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserBody
     */
    isInitSetup?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserBody
     */
    verificationCode?: number;
    /**
     * 
     * @type {string}
     * @memberof UserBody
     */
    pushToken?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserBody
     */
    pushTokenDelete?: boolean;
}

/**
 * Check if a given object implements the UserBody interface.
 */
export function instanceOfUserBody(value: object): value is UserBody {
    return true;
}

export function UserBodyFromJSON(json: any): UserBody {
    return UserBodyFromJSONTyped(json, false);
}

export function UserBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserBody {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'avatar': json['avatar'] == null ? undefined : json['avatar'],
        'isInitSetup': json['isInitSetup'] == null ? undefined : json['isInitSetup'],
        'verificationCode': json['verificationCode'] == null ? undefined : json['verificationCode'],
        'pushToken': json['pushToken'] == null ? undefined : json['pushToken'],
        'pushTokenDelete': json['pushTokenDelete'] == null ? undefined : json['pushTokenDelete'],
    };
}

export function UserBodyToJSON(json: any): UserBody {
    return UserBodyToJSONTyped(json, false);
}

export function UserBodyToJSONTyped(value?: UserBody | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'avatar': value['avatar'],
        'isInitSetup': value['isInitSetup'],
        'verificationCode': value['verificationCode'],
        'pushToken': value['pushToken'],
        'pushTokenDelete': value['pushTokenDelete'],
    };
}

