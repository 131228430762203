import React from "react";

import User from "../../../../Components/layout/User";
import { useCurrentGroup, useOnlineMembers } from "../../../../Context/CurrentGroup";
import { useProfile } from "../../../../Context/Profile";

const OnlineUsers: React.FC = () => {
  const onlineMembers = useOnlineMembers();
  const currentGroup = useCurrentGroup();
  const profile = useProfile();

  return (
    <div data-cy="onlineUsers" className="flex flex-col gap-2 items-start">
      {currentGroup?.members?.map((member) => {
        if (!member.user.name) return; // User didn't create profile yet
        return (
          <User
            key={member.user.id}
            name={member.user.name}
            avatar={member.user.avatar}
            isOnline={onlineMembers?.includes(member.user.id) || member.user.id == profile?.id}
          />
        );
      })}
    </div>
  );
};

export default OnlineUsers;
