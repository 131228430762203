/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddDrinkBody,
  DeleteDrink200Response,
} from '../models/index';
import {
    AddDrinkBodyFromJSON,
    AddDrinkBodyToJSON,
    DeleteDrink200ResponseFromJSON,
    DeleteDrink200ResponseToJSON,
} from '../models/index';

export interface AddDrinkRequest {
    accessToken: string;
    id: string;
    addDrinkBody?: AddDrinkBody;
}

export interface DeleteDrinkRequest {
    accessToken: string;
    id: string;
    drinkId: string;
}

export interface UpdateDrinkRequest {
    accessToken: string;
    id: string;
    drinkId: string;
    addDrinkBody?: AddDrinkBody;
}

/**
 * 
 */
export class GroupDrinksApi extends runtime.BaseAPI {

    /**
     * Add drink
     */
    async addDrinkRaw(requestParameters: AddDrinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling addDrink().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling addDrink().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/drinks`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddDrinkBodyToJSON(requestParameters['addDrinkBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add drink
     */
    async addDrink(requestParameters: AddDrinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addDrinkRaw(requestParameters, initOverrides);
    }

    /**
     * delete drink
     */
    async deleteDrinkRaw(requestParameters: DeleteDrinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteDrink200Response>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling deleteDrink().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteDrink().'
            );
        }

        if (requestParameters['drinkId'] == null) {
            throw new runtime.RequiredError(
                'drinkId',
                'Required parameter "drinkId" was null or undefined when calling deleteDrink().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/drinks/{drinkId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"drinkId"}}`, encodeURIComponent(String(requestParameters['drinkId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteDrink200ResponseFromJSON(jsonValue));
    }

    /**
     * delete drink
     */
    async deleteDrink(requestParameters: DeleteDrinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteDrink200Response | null | undefined > {
        const response = await this.deleteDrinkRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Update drink
     */
    async updateDrinkRaw(requestParameters: UpdateDrinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling updateDrink().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateDrink().'
            );
        }

        if (requestParameters['drinkId'] == null) {
            throw new runtime.RequiredError(
                'drinkId',
                'Required parameter "drinkId" was null or undefined when calling updateDrink().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/groups/{id}/drinks/{drinkId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"drinkId"}}`, encodeURIComponent(String(requestParameters['drinkId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AddDrinkBodyToJSON(requestParameters['addDrinkBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update drink
     */
    async updateDrink(requestParameters: UpdateDrinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDrinkRaw(requestParameters, initOverrides);
    }

}
