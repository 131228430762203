/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteDrink200Response
 */
export interface DeleteDrink200Response {
    /**
     * 
     * @type {string}
     * @memberof DeleteDrink200Response
     */
    alert: string;
}

/**
 * Check if a given object implements the DeleteDrink200Response interface.
 */
export function instanceOfDeleteDrink200Response(value: object): value is DeleteDrink200Response {
    if (!('alert' in value) || value['alert'] === undefined) return false;
    return true;
}

export function DeleteDrink200ResponseFromJSON(json: any): DeleteDrink200Response {
    return DeleteDrink200ResponseFromJSONTyped(json, false);
}

export function DeleteDrink200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteDrink200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'alert': json['alert'],
    };
}

export function DeleteDrink200ResponseToJSON(json: any): DeleteDrink200Response {
    return DeleteDrink200ResponseToJSONTyped(json, false);
}

export function DeleteDrink200ResponseToJSONTyped(value?: DeleteDrink200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'alert': value['alert'],
    };
}

