import { Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useCheckIfUserIsLoggedIn } from "../Context/Auth";
import Login from "./Login/Login";
import { signIn } from "../utils/cognito";
import { MainProvider } from "../Context/Main";
import Main from "./Main/Main";
import { ProfileProvider } from "../Context/Profile";
import Loading from "../Components/layout/Loading";

const App: React.FC = () => {
  const checkIfUserIsLoggedIn = useCheckIfUserIsLoggedIn();

  const navigate = useNavigate();

  useEffect(() => {
    printVersion();
    init();
  }, []);

  // Console log version info
  const printVersion = () => {
    console.log("\x1b[33m%s\x1b[0m", `wiegaaterkoffiehalen.nl frontend versie ${import.meta.env.PACKAGE_VERSION} 🍺`);
    fetch(`${import.meta.env.VITE_API_PATH}/version`).then((response) =>
      response.text().then((data) => console.log("\x1b[33m%s\x1b[0m", `wiegaaterkoffiehalen.nl backend versie ${data} 🔥`))
    );
  };

  const init = async () => {
    const url = window.location.href;
    const groupId = url.includes("/groups/") ? url.split("/groups/")[1].split("/")[0] : null;
    const loginUrl = groupId ? `/login?redirectToGroup=${groupId}` : "/login";
    // Token provided?
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tokenInParameter = urlParams.get("ssoLink");
    if (tokenInParameter) {
      const decoded = atob(tokenInParameter);
      const [email, token] = decoded.split("/");
      try {
        await signIn(email, undefined, token);
      } catch {
        // Token invalid
      }
    } else {
      // No token
      checkIfUserIsLoggedIn && (await checkIfUserIsLoggedIn());
    }
    navigate(loginUrl);
  };

  return (
    <main className="light text-foreground bg-background w-svw h-svh">
      <>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard/groups/:groupId/*"
            element={
              <>
                <Suspense fallback={<Loading />}>
                  <MainProvider>
                    <ProfileProvider>
                      <Main />
                    </ProfileProvider>
                  </MainProvider>
                </Suspense>
              </>
            }
          />
        </Routes>
      </>
    </main>
  );
};
export default App;
