import { useCallback, useState } from "react";
import * as AmplifyAuth from "aws-amplify/auth";
import { useContextSelector, createContext } from "use-context-selector";
import * as Sentry from "@sentry/react";

type SignInCurrentUser = () => Promise<boolean>;
type SignOut = () => void;
type CheckIfUserIsLoggedIn = () => Promise<boolean>;

const useStore = () => {
  const [emailOfCurrentUser, setEmailOfCurrentUser] = useState<string | null>(
    null
  );
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  const signInCurrentUser: SignInCurrentUser = async () => {
    try {
      const session = await AmplifyAuth.fetchAuthSession();
      if (!session.tokens || !session.tokens.accessToken)
        throw new Error("Tried to sign in user, but no valid session found");
      const accessToken = session.tokens.accessToken.toString();
      setAccessToken(accessToken);
    } catch (e) {
      Sentry.captureException(e);
      return false;
    }
    return true;
  };

  const signOut: SignOut = async () => {
    try {
      await AmplifyAuth.signOut();
      setAccessToken(undefined);
      setEmailOfCurrentUser(null);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  // Check if user is logged in
  const checkIfUserIsLoggedIn: CheckIfUserIsLoggedIn = async () => {
    try {
      const user = await AmplifyAuth.fetchUserAttributes();
      if (user.email) setEmailOfCurrentUser(user.email);
      return true;
    } catch (_) {
      return false;
    }
  };

  return {
    accessToken,
    emailOfCurrentUser,
    signOut: useCallback(signOut, []),
    signInCurrentUser: useCallback(signInCurrentUser, []),
    checkIfUserIsLoggedIn: useCallback(checkIfUserIsLoggedIn, []),
  };
};

// Context
interface IAuthContext {
  accessToken: string | undefined;
  emailOfCurrentUser: string | null;
  signOut: SignOut;
  signInCurrentUser: SignInCurrentUser;
  checkIfUserIsLoggedIn: CheckIfUserIsLoggedIn;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = (props: Props) => (
  <AuthContext.Provider value={useStore()}>
    {props.children}
  </AuthContext.Provider>
);

export const useCheckIfUserIsLoggedIn = () =>
  useContextSelector(AuthContext, (s) => s?.checkIfUserIsLoggedIn);
export const useSignInCurrentUser = () =>
  useContextSelector(AuthContext, (s) => s?.signInCurrentUser);
export const useEmailOfCurrentUser = () =>
  useContextSelector(AuthContext, (s) => s?.emailOfCurrentUser);
export const useSignOut = () =>
  useContextSelector(AuthContext, (s) => s?.signOut);
