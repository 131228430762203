import { Amplify } from "aws-amplify";
import * as AmplifyAuth from "aws-amplify/auth";
import * as Sentry from "@sentry/react";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_COGNITO_USERPOOL_ID,
      userPoolClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
      loginWith: {
        email: true,
      },
    },
  },
});

type GetAccessToken = () => Promise<string | undefined>;
export const getAccessToken: GetAccessToken = async () => {
  try {
    const session = await AmplifyAuth.fetchAuthSession();
    if (!session.tokens || !session.tokens.accessToken)
      throw new Error("Failed to get access token");
    return session.tokens.accessToken.toString();
  } catch (e) {
    Sentry.captureException(e);
    return undefined;
  }
};

export type SignIn = (
  email: string,
  password?: string,
  ssoCode?: string
) => Promise<boolean>;
export const signIn: SignIn = async (email, password, token) => {
  try {
    await AmplifyAuth.signOut(); // Sign out previous sessions

    if (password != null) {
      // password login
      const result = await AmplifyAuth.signIn({
        username: email,
        password,
      });
      if (result.isSignedIn != true) throw new Error();
    } else if (token != null) {
      await AmplifyAuth.signIn({
        username: email,
        options: { authFlowType: "CUSTOM_WITHOUT_SRP" },
      });
      const result = await AmplifyAuth.confirmSignIn({
        challengeResponse: token,
      });
      if (result.isSignedIn != true) throw new Error();
    } else {
      throw new Error();
    }
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }
  return true;
};

export type RequestCode = (email: string) => Promise<boolean>;
export const requestCode: RequestCode = async (email) => {
  try {
    await AmplifyAuth.resetPassword({ username: email });
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }
  return true;
};

export type ResetPassword = (
  email: string,
  code: string,
  password: string
) => Promise<boolean>;
export const resetPassword: ResetPassword = async (email, code, password) => {
  try {
    await AmplifyAuth.confirmResetPassword({
      username: email,
      newPassword: password,
      confirmationCode: code,
    });
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }
  return true;
};

export type SignUp = (email: string, password: string) => Promise<boolean>;
export const signUp: SignUp = async (email, password) => {
  try {
    await AmplifyAuth.signOut(); // Sign out previous sessions
    const result = await AmplifyAuth.signUp({ username: email, password });
    if (!result.isSignUpComplete) throw new Error();
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }
  return true;
};
