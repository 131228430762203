import { Alert, Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import { Modal, ModalContent, ModalBody } from "@nextui-org/modal";
import { BiLogoApple, BiLogoAndroid } from "react-icons/bi";
import { useState } from "react";
import QRCode from "react-qr-code";

type Props = { isOpen: boolean; onClose: () => void };

const GetApp: React.FC<Props> = (props) => {
  const [device, setDevice] = useState<String | undefined>(undefined);

  // helpers
  const getCardClass = (forDevice: String) => {
    const opacity = device == forDevice ? "100" : "50";
    return `opacity-${opacity}`;
  };

  const getLink = () => {
    const link = device == "iOS" ? import.meta.env.VITE_APPLINK_IOS : device == "Android" ? import.meta.env.VITE_APPLINK_ANDROID : undefined;
    return link;
  };

  const openLink = () => {
    const link = getLink();
    if (!link) return;
    window.open(link, "_blank")?.focus();
  };

  // Element
  if (!props.isOpen) return;
  return (
    <Modal size="4xl" isDismissable={false} isOpen={true} onClose={props.onClose} classNames={{ body: "flex" }}>
      <ModalContent>
        {() => (
          <>
            <ModalBody className="text-secondary-950">
              <h1 className="text-center">Download de wiegaaterkoffiehalen.nl app</h1>
              <Alert
                color="primary"
                title="Let op!"
                description="De app voor zowel Android als iOS is nog in ontwikkeling. Via onderstaande instructies installeer je de betaversie van de app."
              />
              <div className="flex gap-5">
                <Card fullWidth isPressable onPress={() => setDevice("iOS")} className={getCardClass("iOS")}>
                  <CardHeader>
                    <p className="text-center w-full font-semibold">iOS</p>
                  </CardHeader>
                  <CardBody>
                    <BiLogoApple size="20em" className="max-w-48 mx-auto" />
                  </CardBody>
                </Card>
                <Card fullWidth isPressable onPress={() => setDevice("Android")} className={getCardClass("Android")}>
                  <CardHeader>
                    <p className="text-center w-full font-semibold">Android</p>
                  </CardHeader>
                  <CardBody>
                    <BiLogoAndroid size="20em" className="max-w-48 mx-auto" />
                  </CardBody>
                </Card>
              </div>
              {device && (
                <div className="space-y-4">
                  <p className="text-center">Scan onderstaande QR code</p>
                  <QRCode className="mx-auto" value={getLink()!} />
                  <p className="text-center">Of klik op onderstaande link</p>
                  <Button color="primary" onPress={openLink} fullWidth>
                    Download {device} app
                  </Button>
                </div>
              )}
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default GetApp;
