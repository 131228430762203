/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MyProfile } from './MyProfile';
import {
    MyProfileFromJSON,
    MyProfileFromJSONTyped,
    MyProfileToJSON,
    MyProfileToJSONTyped,
} from './MyProfile';
import type { MyGroups } from './MyGroups';
import {
    MyGroupsFromJSON,
    MyGroupsFromJSONTyped,
    MyGroupsToJSON,
    MyGroupsToJSONTyped,
} from './MyGroups';

/**
 * 
 * @export
 * @interface MyProfileObject
 */
export interface MyProfileObject {
    /**
     * 
     * @type {MyProfile}
     * @memberof MyProfileObject
     */
    profile: MyProfile;
    /**
     * 
     * @type {Array<MyGroups>}
     * @memberof MyProfileObject
     */
    groups: Array<MyGroups>;
}

/**
 * Check if a given object implements the MyProfileObject interface.
 */
export function instanceOfMyProfileObject(value: object): value is MyProfileObject {
    if (!('profile' in value) || value['profile'] === undefined) return false;
    if (!('groups' in value) || value['groups'] === undefined) return false;
    return true;
}

export function MyProfileObjectFromJSON(json: any): MyProfileObject {
    return MyProfileObjectFromJSONTyped(json, false);
}

export function MyProfileObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): MyProfileObject {
    if (json == null) {
        return json;
    }
    return {
        
        'profile': MyProfileFromJSON(json['profile']),
        'groups': ((json['groups'] as Array<any>).map(MyGroupsFromJSON)),
    };
}

export function MyProfileObjectToJSON(json: any): MyProfileObject {
    return MyProfileObjectToJSONTyped(json, false);
}

export function MyProfileObjectToJSONTyped(value?: MyProfileObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'profile': MyProfileToJSON(value['profile']),
        'groups': ((value['groups'] as Array<any>).map(MyGroupsToJSON)),
    };
}

