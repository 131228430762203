import { Button, Card, CardBody, Input, Alert } from "@nextui-org/react";
import { useEffect, useState } from "react";
import Emojis from "../../../Components/Emojis";
import useValidator from "../../../hooks/useValidator";
import { woman } from "../../../assets/lottie";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { useProfile, useUpdateProfileFunction } from "../../../Context/Profile";

const Onboard: React.FC = () => {
  // Context
  const profile = useProfile();
  const updateProfileFunction = useUpdateProfileFunction();

  // States
  const [name, setName] = useState<string | undefined>(undefined);
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  const [verificationCode, setVerificationCode] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const nameValide = useValidator([
    { value: name, min: 3, max: 20, regex: /^[a-z\s]+$/gi },
  ]);

  // Hooks
  const verificationCodeValide = useValidator([
    { value: verificationCode, min: 6, max: 6, regex: /^[0-9]+$/gi },
  ]);

  // Effects
  // Load profile
  useEffect(() => {
    if (profile) {
      setName(profile.name);
      setAvatar(profile.avatar);
    }
  }, []);

  // Functions
  const handleSubmit = async () => {
    if (!name || !avatar || !verificationCode) return;
    setIsLoading(true);
    if (!updateProfileFunction) return;
    const succeeded = await updateProfileFunction({
      name,
      avatar,
      InitSetup: true,
      verificationCode,
    });
    if (!succeeded) setError("Mislukt. Klopt de verificatiecode?");
    setIsLoading(false);
  };

  return (
    <div className="flex h-full align-items-center">
      <div className="flex w-11/12 xl:w-auto max-w-7xl items-center m-auto">
        <Card className="h-min w-full xl:min-w-[780px] p-8">
          <CardBody>
            <div className="flex flex-row items-center gap-6">
              <div
                className="flex flex-col gap-4 items-center flex-1"
                id="loginSecond"
              >
                <Lottie
                  loop
                  animationData={woman}
                  play
                  speed={0.1}
                  className="w-80"
                />
                <Alert
                  title="Welkom op wiegaaterkoffiehalen.nl"
                  description="Stel je hieronder even voor"
                  color="primary"
                  variant="bordered"
                />
                {error && <Alert color="primary" title={error} />}
                <Input
                  type="text"
                  label="Verificatiecode (per email ontvangen)"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  isRequired
                  // onKeyDown={(e) => e.key == "Enter" && props.handleSubmit()}
                  value={verificationCode}
                />
                <Input
                  type="text"
                  label="Je naam"
                  onChange={(e) => setName(e.target.value)}
                  isRequired
                  // onKeyDown={(e) => e.key == "Enter" && props.handleSubmit()}
                  value={name}
                />
                <Emojis
                  current={avatar}
                  onClick={(filename) => setAvatar(filename)}
                />
                <Button
                  onPress={handleSubmit}
                  isLoading={isLoading}
                  isDisabled={!nameValide || !verificationCodeValide || !avatar}
                  fullWidth
                  color="primary"
                >
                  Profiel aanmaken
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Onboard;
