/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationBody
 */
export interface NotificationBody {
    /**
     * 
     * @type {string}
     * @memberof NotificationBody
     */
    method: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationBody
     */
    value: boolean;
}

/**
 * Check if a given object implements the NotificationBody interface.
 */
export function instanceOfNotificationBody(value: object): value is NotificationBody {
    if (!('method' in value) || value['method'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function NotificationBodyFromJSON(json: any): NotificationBody {
    return NotificationBodyFromJSONTyped(json, false);
}

export function NotificationBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationBody {
    if (json == null) {
        return json;
    }
    return {
        
        'method': json['method'],
        'value': json['value'],
    };
}

export function NotificationBodyToJSON(json: any): NotificationBody {
    return NotificationBodyToJSONTyped(json, false);
}

export function NotificationBodyToJSONTyped(value?: NotificationBody | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'method': value['method'],
        'value': value['value'],
    };
}

