/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MyProfile
 */
export interface MyProfile {
    /**
     * 
     * @type {string}
     * @memberof MyProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MyProfile
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MyProfile
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof MyProfile
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MyProfile
     */
    pushTokens: Array<string>;
}

/**
 * Check if a given object implements the MyProfile interface.
 */
export function instanceOfMyProfile(value: object): value is MyProfile {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('pushTokens' in value) || value['pushTokens'] === undefined) return false;
    return true;
}

export function MyProfileFromJSON(json: any): MyProfile {
    return MyProfileFromJSONTyped(json, false);
}

export function MyProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): MyProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['_id'],
        'email': json['email'],
        'avatar': json['avatar'] == null ? undefined : json['avatar'],
        'name': json['name'] == null ? undefined : json['name'],
        'pushTokens': json['pushTokens'],
    };
}

export function MyProfileToJSON(json: any): MyProfile {
    return MyProfileToJSONTyped(json, false);
}

export function MyProfileToJSONTyped(value?: MyProfile | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        '_id': value['id'],
        'email': value['email'],
        'avatar': value['avatar'],
        'name': value['name'],
        'pushTokens': value['pushTokens'],
    };
}

