import { useCallback, useState } from "react";
import * as Api from "../api-sdk";
import { getAccessToken } from "../utils/cognito";
import { useContextSelector, createContext } from "use-context-selector";
import * as Sentry from "@sentry/react";

const configuration = new Api.Configuration({
  basePath: import.meta.env.VITE_API_PATH
});

const useStore = () => {
  const [currentGroup, setCurrentGroup] = useState<Api.Group | null>(null);
  const [onlineMembers, setOnlineMembers] = useState<string[]>([]);

  const loadGroupById = async (groupId: string) => {
    try {
      const accessToken = await getAccessToken();
      if (!accessToken) throw new Error("Failed to get access token");
      const groupApi = new Api.GroupsApi(configuration);
      const groupMemberApi = new Api.GroupMembersApi(configuration);
      const group = await groupApi.getGroup({ accessToken, id: groupId });
      const onlineUsers = await groupMemberApi.getOnlineUsers({ accessToken, id: groupId });
      setCurrentGroup(group);
      setOnlineMembers(onlineUsers);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const refresh = async () => {
    if (currentGroup?.id) {
      loadGroupById(currentGroup.id);
    }
  };

  return {
    currentGroup,
    onlineMembers,
    loadGroupById: useCallback(loadGroupById, []),
    addOnlineMember: useCallback((user: string) => setOnlineMembers((old) => (old.includes(user) ? old : [...old, user])), [onlineMembers]),
    removeOnlineMember: useCallback((user: string) => setOnlineMembers((old) => [...old].filter((_user) => _user != user)), [onlineMembers]),
    refresh: useCallback(refresh, [currentGroup?.id])
  };
};

// Context

interface ICurrentGroupContext {
  currentGroup: Api.Group | null;
  onlineMembers: string[];
  loadGroupById: (groupId: string) => Promise<void>;
  addOnlineMember: (user: string) => void;
  removeOnlineMember: (user: string) => void;
  refresh: () => Promise<void>;
}

export const CurrentGroupContext = createContext<ICurrentGroupContext | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export const CurrentGroupProvider = (props: Props) => (
  <CurrentGroupContext.Provider value={useStore()}>{props.children}</CurrentGroupContext.Provider>
);

export const useCurrentGroup = () => useContextSelector(CurrentGroupContext, (s) => s?.currentGroup);
export const useOnlineMembers = () => useContextSelector(CurrentGroupContext, (s) => s?.onlineMembers);
export const useLoadGroupById = () => useContextSelector(CurrentGroupContext, (s) => s?.loadGroupById);
export const useRefreshCurrentGroup = () => useContextSelector(CurrentGroupContext, (s) => s?.refresh);
export const useAddOnlineMember = () => useContextSelector(CurrentGroupContext, (s) => s?.addOnlineMember);
export const useRemoveOnlineMember = () => useContextSelector(CurrentGroupContext, (s) => s?.removeOnlineMember);
export const useCurrentGroupId = () => useContextSelector(CurrentGroupContext, (s) => s?.currentGroup?.id);
