/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MyProfileObject,
  SsoToken,
  UserBody,
} from '../models/index';
import {
    MyProfileObjectFromJSON,
    MyProfileObjectToJSON,
    SsoTokenFromJSON,
    SsoTokenToJSON,
    UserBodyFromJSON,
    UserBodyToJSON,
} from '../models/index';

export interface GetProfileRequest {
    accessToken: string;
}

export interface GetSsoRequest {
    accessToken: string;
}

export interface UpdateUserRequest {
    accessToken: string;
    userBody?: UserBody;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Get own profile
     */
    async getProfileRaw(requestParameters: GetProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MyProfileObject>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling getProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MyProfileObjectFromJSON(jsonValue));
    }

    /**
     * Get own profile
     */
    async getProfile(requestParameters: GetProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MyProfileObject> {
        const response = await this.getProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get SSO token
     */
    async getSsoRaw(requestParameters: GetSsoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SsoToken>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling getSso().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/users/ssotoken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SsoTokenFromJSON(jsonValue));
    }

    /**
     * Get SSO token
     */
    async getSso(requestParameters: GetSsoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SsoToken> {
        const response = await this.getSsoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update user
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accessToken'] == null) {
            throw new runtime.RequiredError(
                'accessToken',
                'Required parameter "accessToken" was null or undefined when calling updateUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['accessToken'] != null) {
            headerParameters['access-token'] = String(requestParameters['accessToken']);
        }

        const response = await this.request({
            path: `/users`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserBodyToJSON(requestParameters['userBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update user
     */
    async updateUser(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserRaw(requestParameters, initOverrides);
    }

}
