/* tslint:disable */
/* eslint-disable */
/**
 * Swagger wiegaaterkoffiehalen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlaceOrderBody
 */
export interface PlaceOrderBody {
    /**
     * 
     * @type {string}
     * @memberof PlaceOrderBody
     */
    drink: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceOrderBody
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof PlaceOrderBody
     */
    extras?: string;
}

/**
 * Check if a given object implements the PlaceOrderBody interface.
 */
export function instanceOfPlaceOrderBody(value: object): value is PlaceOrderBody {
    if (!('drink' in value) || value['drink'] === undefined) return false;
    return true;
}

export function PlaceOrderBodyFromJSON(json: any): PlaceOrderBody {
    return PlaceOrderBodyFromJSONTyped(json, false);
}

export function PlaceOrderBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceOrderBody {
    if (json == null) {
        return json;
    }
    return {
        
        'drink': json['drink'],
        'text': json['text'] == null ? undefined : json['text'],
        'extras': json['extras'] == null ? undefined : json['extras'],
    };
}

export function PlaceOrderBodyToJSON(json: any): PlaceOrderBody {
    return PlaceOrderBodyToJSONTyped(json, false);
}

export function PlaceOrderBodyToJSONTyped(value?: PlaceOrderBody | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'drink': value['drink'],
        'text': value['text'],
        'extras': value['extras'],
    };
}

